import { BrowserRouter as Router, Navigate, Route, Routes, Link, withRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useEffect } from 'react';


function App() {


  useEffect(() => {
    window.location.replace('https://acintyaschool.com/')
  }, [])


  return (
    <div className="App b">


    </div>
  );
}

export default App;
